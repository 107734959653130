#wrapper{
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;

    img{
        image-rendering: -webkit-optimize-contrast;
    }

    #site-header {
        position: relative;
        top: 0px;
        z-index: 1;

        .localization-menu {
            display: block;
            position: absolute;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            list-style: none;
            z-index: 99;
        }

        .floating-controls{
            z-index: 10;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 50px;

                a,
                a:hover,
                a:visited,
                a:active{
                    color: black;
                    text-decoration: none;
                    font-size: 2em;
                }
        }

        #previous-slide-btn,
        #previous-section-btn{
            position: absolute;
            left: 50px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
        }
    }

    .content{
        z-index: 0;
    }
}

#section3{
    .fp-tableCell{
        vertical-align: middle;
    }

    .section-optin{
        margin-top: 0px !important;
    }
}

.content.content--disclaimer {
    margin: 0 auto;
    margin-top: 15%;
    padding: 20px;
    width: 100%;
    max-width: 900px;

    h2{
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 768px){
    #wrapper{
        #site-header{
            .localization-menu{
                position: relative;
                top: 0px;
                left: 50%;
                transform: translateX(-50%);
                right: 0px;
                padding: 0px 10px 20px 10px;
            }

            .logo-container{
                img{
                    margin: 0 auto;
                    width: 120px;
                    image-rendering: -webkit-optimize-contrast;
                }

                h1{
                    font-size: 1.5em;
                }
            }

            .floating-controls{
                right: 20px;
                    a,
                    a:hover,
                    a:visited,
                    a:active{
                        font-size: 2em;
                    }
            }

            #previous-slide-btn,
            #previous-section-btn{
                left: 20px;
            }
        }

        #section3{
            .fp-tableCell{
                vertical-align: middle;
            }
        
            .section-optin{
                margin-top: 0px;
            }
        }
    }
    
    .content.content--disclaimer{
        position: relative;
        top: unset;
        transform: unset;
        left: unset;

        .call-to-action{
            a{
                width: 100%;
                display: block;

                button{
                    width: inherit;
                }
            }
            
        }
    }
}

@media screen and (max-width: 480px){
    .assessment_div{
        #section1, #section2{
            label{
                width: 100%;
            }
        }

        #get_started {
            font-size: 0.5em;
        }
    }

    #wrapper{
        #site-header{
            z-index: 1000;

            .logo-container{
                margin: 10px 0px;

                img{
                    width: 70px;
                }

                h1{
                    font-size: 0.8em;
                }
            }
        }
    }

    .section-optin-main{
        margin-bottom: 0px;
    }

}

@media screen and (max-height: 480px){
    #wrapper{
        #site-header{
            position: fixed;
            z-index: 1000;

            .logo-container{
                margin: 10px 0px;

                img{
                    width: 70px;
                }

                h1{
                    font-size: 0.8em;
                }
            }
        }

        .section_1{
            margin-top: 100px;
        }

        .content.content--disclaimer {
            margin-top: 22%;
        }
    }

    .assessment_div{
        font-size: 9px;
        margin-top: 0px;
        padding-top: 30px;

        #section1 label, #section2 label {
            width: max-content;
            display: inline-block !important;
            margin: 0px;
            padding: 5px;
        }

        #section1 label.age, #section2 label.age {
            width: 150px;
            margin: 0px !important;
        }
    }

    #fullpage{
        .fp-tableCell{
            vertical-align: top;
            padding-top: 40px;
        }

        .hello-container{
            top: 0px;
        }
    }

    #section3 .section-optin{
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

    #section3{
        .main-slide{
            margin-top: 0px;
        }

        .section-optin-main{
            margin-bottom: 0px;
        }
    }
}